<template>
  <div
    :class="{
      'overflow-x': scrollX,
    }"
  >
    <input
      type="text"
      class="form-input w-1/2 hover:shadow-primary-sm focus:ring-0 focus:shadow-primary focus:border-primary shadow-primary border-primary rounded-lg mb-4"
      placeholder="Search..."
      v-model="search"
      @input="handleSearch"
      v-if="searchable"
    />
    <div
      :class="{
        'overflow-scroll w-full': scrollX,
      }"
    >
      <table
        class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700"
        aria-describedby="table-component-gista"
      >
        <thead class="bg-white text-gray-darkest">
          <tr v-if="groupHeaders.length > 0">
            <th
              v-for="(head, i) in groupHeaders"
              :key="i"
              :id="i"
              :colspan="head.colspan"
              class="px-5 py-3 border-b-2 border-t-2 border-primary bg-gray-100 text-sm font-semibold text-gray-700 tracking-wider"
              :class="{
                'text-left': head.align === 'start',
                'text-center': head.align === 'center',
                'text-right': head.align === 'end',
                'border-l-2': head.headerColumnBorderLeft || false,
              }"
            >
              <span class="relative">
                {{ head.text }}
              </span>
            </th>
          </tr>
          <tr>
            <th
              v-for="(head, i) in headers"
              :key="i"
              :id="i"
              class="px-5 py-3 border-b-2 border-primary bg-gray-100 text-sm font-semibold text-gray-700 tracking-wider"
              :class="{
                'text-left': head.align === 'start',
                'text-center': head.align === 'center',
                'text-right': head.align === 'end',
                'border-l-2': head.headerColumnBorderLeft || false,
                'sticky-right bg-white':
                  stickyRight && i + 1 === headers.length,
                'sticky-left bg-white': stickyLeft && i === 0,
              }"
            >
              <span v-if="head.value === 'checkall'" class="relative">
                <gista-checkbox
                  v-model="isSelectAll"
                  :disabled="checkallDisabled"
                  @change="selectAllItem"
                >
                </gista-checkbox>
              </span>
              <span
                v-else
                class="relative"
                @click="head.sortable && sortBy($event, head.value)"
              >
                {{ head.text }}
                <span
                  :class="{
                    'sort-desc': findSortDirection(head.value) === 'desc',
                    'sort-asc': findSortDirection(head.value) === 'asc',
                  }"
                ></span>
              </span>
              <button
                class="rounded-full h-5 w-5 inline-flex justify-center items-center bg-primary-lightest ml-1 text-xs text-primary-dark"
                @click="removeSorting(head.value)"
                v-if="findSort(head.value) && sortOrder.length > 1"
              >
                {{ findSortIndex(head.value) + 1 }}
              </button>
            </th>
          </tr>
        </thead>
        <tbody class="text-gray-dark">
          <tr v-if="showNotFound && tableData.length < 1">
            <td
              :colspan="headers.length"
              class="bg-primary-lightest bg-opacity-25 text-center p-4"
            >
              {{ notFoundLabel }}
            </td>
          </tr>
          <tr
            v-for="(data, i) in tableData"
            :key="i"
            :class="[rowClass(data, i)]"
          >
            <td
              class="p-4 border-primary"
              v-for="(head, j) in headers"
              :key="head.value"
              :class="[
                tdClass,
                {
                  'border-l-2': head.cellColumnBorderLeft || false,
                  'sticky-right': stickyRight && j + 1 === headers.length,
                  'sticky-left': stickyLeft && j === 0,
                  'bg-col-primary': !rowClass(data, i) && i % 2 === 0,
                  'bg-white': !rowClass(data, i) && i % 2 !== 0,
                },
                rowClass(data, i),
                head.width,
              ]"
            >
              <div
                :class="[
                  'flex',
                  {
                    'justify-start': head.align === 'start',
                    'justify-center': head.align === 'center',
                    'justify-end': head.align === 'end',
                  },
                  head.width,
                  wordWrap(head.width),
                ]"
              >
                <slot
                  :name="head.value"
                  :slot-props="{ data, index: getCurrentIndex(i) }"
                  >{{ data[head.value] }}</slot
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-group text-gray-dark space-x-1" v-if="showPagination">
      <PaginationGista
        :current="currentPage"
        :total="totalRecords"
        :per-page="pageCount"
        :paging="paging"
        @page-changed="handlePageChange($event)"
        @paging-change="handlePagingChange($event)"
      >
        <template slot="footer-action">
          <slot name="footer-action"></slot>
        </template>
      </PaginationGista>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import PaginationGista from "./Pagination.vue";

export default {
  name: "TableGista",
  components: {
    PaginationGista,
  },
  props: {
    items: {
      type: [Array, Function],
      default: () => [],
    },
    groupHeaders: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectAllState: {
      type: Boolean,
    },
    headers: {
      type: Array,
      required: true,
    },
    sortOrder: {
      type: Array,
    },
    options: {
      type: Object,
    },
    notFoundLabel: {
      type: String,
      default: "Data not found",
    },
    showNotFound: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    searchQuery: {
      type: String,
      default: null,
    },
    rowClass: {
      type: Function,
      default: () => "",
    },
    serverSide: {
      type: Boolean,
      default: false,
    },
    tdClass: {
      type: String,
      default: "",
    },
    scrollX: {
      type: Boolean,
      default: true,
    },
    checkallDisabled: {
      type: Boolean,
      default: false,
    },
    disableUpdateOptions: {
      type: Boolean,
      default: false,
    },
    stickyRight: {
      type: Boolean,
      default: false,
    },
    stickyLeft: {
      type: Boolean,
      default: false,
    },
    paging: {
      type: [Array, Function],
      default: () => [10, 25, 50, 100],
    },
  },
  data() {
    return {
      records: [],
      search: "",
      isSelectAll: false,
      pageIndex: 0,
      pageCount: this.options.itemsPerPage || 10,
      currentPage: 1,
      totalPage: 0,
      totalRecords: 0,
    };
  },
  mounted() {
    this.mapDataToRows();
  },
  computed: {
    sortData() {
      const obj = this;
      if (this.usesLocalData) {
        obj.records = obj.items;
        if (obj.sortOrder.length) {
          const fieldOrder = obj.sortOrder.map((q) => q.field)[0];
          const orderDirection = obj.sortOrder.map((q) => q.direction)[0];

          if (orderDirection === "desc") {
            obj.records = obj.records.sort((a, b) =>
              b[fieldOrder].localeCompare(a[fieldOrder], undefined, {
                numeric: true,
              })
            );
          } else {
            obj.records = obj.records.sort((a, b) =>
              a[fieldOrder].localeCompare(b[fieldOrder], undefined, {
                numeric: true,
              })
            );
          }

          // obj.records = _.orderBy(
          //   obj.records,
          //   obj.sortOrder.map((q) => q.field),
          //   obj.sortOrder.map((q) => q.direction)
          // );
        }
      }

      return obj.records;
    },
    filterData() {
      const obj = this;
      const data = obj.sortData
        .filter((data) => {
          const rows = obj.headers
            .filter((head) => head.sortable)
            .map((q) => q.value);
          let flag = false;
          if (rows.length > 0) {
            rows.forEach((rowsKey) => {
              if (data[rowsKey] != undefined) {
                if (
                  data[rowsKey]
                    .toString()
                    .toLowerCase()
                    .indexOf(obj.search.toString().trim().toLowerCase()) != -1
                ) {
                  flag = true;
                }
              }
            });
            return flag;
          }
          return data;
        })
        .map((data) => {
          if (obj.search == "") {
            return data;
          }
          return JSON.parse(JSON.stringify(data));
        });
      obj.totalRecords = data.length;
      obj.countPage(data.length);
      return data;
    },
    tableData() {
      const obj = this;
      if (this.usesLocalData) {
        let start = obj.pageIndex * obj.pageCount;
        let end = (obj.pageIndex + 1) * obj.pageCount;

        return obj.filterData.slice(start, end);
      }

      return obj.records;
    },
    usesLocalData() {
      return !this.serverSide;
    },
  },
  watch: {
    searchQuery: _.debounce(function () {
      const obj = this;
      if (!obj.searchable && obj.searchQuery !== null) {
        obj.search = obj.searchQuery;
        obj.mapDataToRows();
      }
    }, 500),
    items() {
      this.mapDataToRows();
    },
    options(data) {
      this.pageIndex = data.page - 1;
      this.currentPage = data.page;
      this.pageCount = data.itemsPerPage;
    },
    selectAllState(value) {
      this.isSelectAll = value;
    },
  },
  methods: {
    selectAllItem() {
      this.$emit("selectAll", this.isSelectAll);
    },
    mapDataToRows() {
      return this.usesLocalData ? this.tableData : this.fetchServerData();
    },
    fetchServerData() {
      const obj = this;
      obj.totalRecords = obj.options.totalItems;
      obj.records = obj.items;

      // dont update options if disableUpdateOptions === true
      if (this.disableUpdateOptions) {
        return;
      }

      obj.$emit("update:options", {
        page: obj.currentPage || 1,
        itemsPerPage: obj.pageCount,
        totalItems: obj.totalRecords,
      });
    },
    countPage(total = 0) {
      const obj = this;
      if (total < 1) {
        return (obj.totalPage = 0);
      }
      return (obj.totalPage =
        total < obj.pageCount ? 1 : Math.floor(total / obj.pageCount));
    },
    sortBy(event, key) {
      const obj = this;
      const index = obj.sortOrder.findIndex((e) => e.field === key);
      if (index === -1) {
        if (!event.shiftKey) {
          obj.sortOrder = [];
        }

        obj.sortOrder.push({ field: key, direction: "asc" });
      } else {
        if (obj.sortOrder[index].direction === "desc") {
          obj.sortOrder.splice(index, 1);
        } else {
          obj.sortOrder[index].direction = "desc";
        }
      }
      obj.$emit("update:sort-order", obj.sortOrder);
      this.detectUpdate();

      if (obj.usesLocalData) {
        return obj.sortData;
      } else {
        return obj.mapDataToRows();
      }
    },
    removeSorting(value) {
      const obj = this;
      obj.sortOrder = obj.sortOrder.filter((q) => q.field !== value);

      obj.$emit("update:sort-order", obj.sortOrder);
      this.detectUpdate();
    },
    handleSearch: _.debounce(function () {
      this.pageIndex = 0;
      this.currentPage = 1;

      this.mapDataToRows();
      this.detectUpdate();
    }, 500),
    handlePageChange(event) {
      this.currentPage = event;
      this.pageIndex = event - 1;

      this.mapDataToRows();
      this.detectUpdate();
    },
    handlePagingChange(event) {
      this.pageIndex = 0;
      this.currentPage = 1;
      this.pageCount = event;

      this.mapDataToRows();
      this.detectUpdate();
    },
    findSort(field) {
      return this.sortOrder.find((sort) => sort.field === field);
    },
    findSortDirection(field) {
      return this.findSort(field) ? this.findSort(field).direction : false;
    },
    findSortIndex(field) {
      return this.sortOrder.findIndex((sort) => sort.field === field);
    },
    getCurrentIndex(index) {
      return this.pageIndex * this.pageCount + index;
    },
    detectUpdate() {
      this.$emit("refresh");
    },

    wordWrap(width) {
      if (width) {
        return "break-all wrap-word";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.wrap-word {
  word-wrap: break-word;
}

.sticky-left {
  position: sticky;
  left: 0;
  z-index: 1;
}
.sticky-right {
  position: sticky;
  right: 0;
  z-index: 2;
}
.bg-col-primary {
  background-color: #edfdfc;
}
</style>
