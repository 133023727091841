<template>
  <div class="flex items-center cursor-pointer relative">
    <div
      :id="idName"
      class="flex items-center rounded-full p-1 duration-300 cursor-pointer"
      :class="{
        'hover:shadow-gray-sm cursor-not-allowed':
          readOnly && !disabled && !value,
        'hover:shadow-primary-sm cursor-not-allowed':
          readOnly && !disabled && value,
        'bg-gray-light': !value,
        'bg-primary': value,
        'bg-gray-lightest cursor-not-allowed': disabled && !value,
        'bg-primary-lightest cursor-not-allowed': disabled && value,
        'w-12 h-7': size == 'md',
        'w-16 h-9': size == 'lg',
        'w-20 h-11': size == 'xl',
      }"
      :aria-checked="value.toString()"
      @click="!disabled && !readOnly && toggle()"
    >
      <div
        class="bg-white rounded-full shadow-md transform duration-300"
        :class="{
          'w-5 h-5': size == 'md',
          'w-7 h-7': size == 'lg',
          'w-9 h-9': size == 'xl',
          'translate-x-5': value && size == 'md',
          'translate-x-7': value && size == 'lg',
          'translate-x-9': value && size == 'xl',
        }"
      ></div>
    </div>
    <span class="ml-2 text-gray-900 text-sm font-medium"><slot></slot></span>
  </div>
</template>

<script>
/*
  <switch-gista
    v-model="item.active"
    :disabled="item.disabled"
    :size="item.size"
    :read-only="item.readOnly"
  >
    {{ item.label }}
  </switch-gista>
*/

export default {
  name: "SwitchGista",
  props: {
    idName: String,
    value: {
      // for v-model
      type: Boolean,
      default: false,
    },
    size: {
      // md, lg, xl
      type: String,
      default: "md",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
