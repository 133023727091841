<template>
  <div class="flex flex-col">
    <label
      class="block text-sm text-gray md:text-left mb-1 pr-4 truncate"
      :class="{ 'text-error': error, 'mb-1': label }"
    >
      {{ label }} <span v-if="mandatory" class="text-error">*</span>
    </label>
    <div class="relative text-base w-full">
      <div
        class="flex items-center border rounded-md"
        :class="{
          'border border-gray-lightest bg-gray-lightest text-gray-light cursor-not-allowed':
            disabled,
          'bg-white border hover:shadow-primary-md': !disabled,
          'border-primary': !isFocus,
          'hover:shadow-secondary-sm shadow-secondary-sm border-secondary':
            isFocus,
          'border-error text-error': error,
          'text-sm h-11': this.size == 'small',
          'text-base h-12': this.size == 'medium',
          'text-base h-14': this.size == 'large',
        }"
      >
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          v-if="iconLeft"
          :class="{
            'text-base': this.size == 'small',
            'text-2xl': this.size == 'medium',
            'text-2xl': this.size == 'large',
          }"
        >
          <span :class="iconLeft"></span>
        </div>
        <input
          v-bind="$attrs"
          type="text"
          class="w-full border-none focus:ring-0 rounded-md placeholder-gray-light text-gray"
          :class="{
            'pl-8': iconLeft,
            ' bg-gray-lightest text-gray-light cursor-not-allowed': disabled,
          }"
          @input="onChange"
          :placeholder="placeholder"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter"
          @focus="isFocus = !isFocus"
          @blur="isFocus = !isFocus"
          :disabled="disabled"
          :readonly="readOnly"
          :value="search"
        />
        <!-- v-model="search" -->
        <div
          class="text-base pr-2 items-center flex inset-y-0 right-0 text-center"
          :class="{
            'text-base h-11': this.size == 'small',
            'text-2xl h-12': this.size == 'medium',
            'text-2xl h-14': this.size == 'large',
          }"
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="!readOnly && isOpen ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
      <div
        v-show="isOpen"
        class="absolute left-0 right-0 mb-4 rounded-md bg-white shadow-primary-sm z-50"
        style="max-height: 300px; overflow: auto"
        ref="scrollSearch"
        @scroll="eventScroll"
      >
        <ul>
          <li
            class="px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white"
            v-if="results.length < 1 && !isLoading"
          >
            <slot name="emptyResult"> No item found </slot>
          </li>
          <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result)"
            class="px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white"
          >
            <slot name="option" :slot-props="result">
              {{ typeof result === "object" ? result[textField] : result }}
            </slot>
          </li>
          <li
            v-if="isLoading"
            class="px-3 py-2 transition-colors duration-100 text-sm text-center"
          >
            <slot name="loading"> Sedang Ambil Data... </slot>
          </li>
        </ul>
      </div>
      <div class="text-xs mt-1" v-if="hint && !dense">
        {{ hint }}
      </div>
      <div v-if="error" class="text-danger text-xs">
        <slot name="message"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutocompleteGista",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    serverSide: {
      type: Boolean,
      default: false,
    },
    valueField: {
      type: String,
      required: false,
      default: "value",
    },
    textField: {
      type: String,
      required: false,
      default: "text",
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: [Object, String],
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    disabled: Boolean,
    error: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    size: {
      // if large -> text-lg, else text-small
      type: String,
      default: "medium",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    hint: String,
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      result: null,
      timeout: null,
      timeoutKeyPress: null,
      isOpen: false,
      isFocus: false,
      results: [],
      search:
        typeof this.value === "object"
          ? this.value[this.textField]
          : this.value,
      arrowCounter: -1,
      page: 1,
    };
  },
  watch: {
    options: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
      }
    },
    // results() {
    //   this.search = this.result;
    // },
    value() {
      this.search = this.value;
    },
    isFocus: function (value) {
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.isOpen = value;
        if (this.search == "" && !this.result) {
          this.search = "";
          this.$emit("input", this.search);
          this.$emit("updateAutoComplete", null);
        } else {
          let result = this.result;
          if (result != null) {
            this.$emit("updateAutoComplete", result);
            if (typeof result == "object") {
              this.search = result[this.textField];
            } else {
              this.search = result;
            }
          }
        }
      }, 500);
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.results = Object.assign([], this.options);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    eventScroll() {
      let container = this.$refs.scrollSearch;
      let totalHeightScroll = Math.floor(
        container.scrollTop + container.clientHeight + 5
      );
      if (totalHeightScroll >= container.scrollHeight && !this.isLoading) {
        this.$emit("input", this.search == undefined ? "" : this.search);
      }
    },
    setResult(result) {
      this.result = result;
      this.isOpen = false;
    },
    onChange(e) {
      this.search = e.target.value;
      if (this.search == "") {
        this.result = null;
      }
      this.$emit("input", this.search);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    async onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.isOpen = false;
      this.arrowCounter = -1;
    },
  },
};
</script>
