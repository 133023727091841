<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <label
        style="white-space: normal"
        class="block text-sm text-gray font-medium"
        :class="{ 'text-error': error, 'mb-1': label }"
      >
        {{ label }} <span v-if="mandatory" class="text-error">*</span>
      </label>
    </div>
    <div
      class="pl-0 rounded-lg border border-primary items-center focus:border-secondary focus:shadow-secondary-round flex flex-row"
      :class="{ 'border-gray-lightest': disabled, 'border-error': error }"
    >
      <div class="w-full flex">
        <date-picker
          v-model="localInputDateRange"
          mode="date"
          :masks="masks"
          @input="handleInput"
          is-range
          class="w-full"
          v-if="showDatepicker"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex w-full items-center">
              <input
                v-bind="$attrs"
                class="h-12 p-2.5 pr-0 border-none rounded-lg focus:outline-none placeholder-gray-light w-full text-gray"
                :class="{
                  'bg-gray-lightest border-gray-lightest': disabled,
                  'border-error': error,
                }"
                :value="
                  inputValue.start != null
                    ? inputValue.start + ' ~ ' + inputValue.end
                    : null
                "
                :placeholder="placeholder"
                :disabled="disabled"
                v-on="inputEvents.start"
                readonly
              />
              <div @click="handleClear" class="mr-2 my-2 cursor-pointer">
                <svg
                  class="text-gray-600 w-6 pointer-events-none"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="!disabled && !isValueEmpty"
                >
                  <path
                    d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
                    fill="#666666"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                    fill="#666666"
                  />
                </svg>
              </div>
              <svg
                v-if="!disabled"
                class="text-gray-600 w-6 mr-2 my-2 pointer-events-none"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 2V6"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 2V6"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 10H21"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </date-picker>
      </div>
    </div>
    <div
      v-if="this.$slots['message'] != undefined"
      class="text-xs mt-1"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  name: "DatepickerRangeGista",
  components: {
    DatePicker,
  },
  data() {
    return {
      defaultValue: { start: "", end: "" },
      localInputDateRange: this.value,
      masks: {
        input: "DD/MM/YYYY",
      },
      showDatepicker: true,
    };
  },
  props: {
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select a date range",
    },
    label: {
      type: String,
      default: "",
    },
    mandatory: Boolean,
    format: {
      type: String,
      default: "DD/MM/YYYY",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: () => {
        return {
          start: "",
          end: "",
        };
      },
    },
    error: {
      // error flag
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
    handleClear() {
      this.inputValue = this.defaultValue;
      this.localInputDateRange = this.defaultValue;
      this.$emit("clear", this.inputValue);
    },
    clearDate() {
      this.$emit("clear");
    },
  },
  computed: {
    isValueEmpty() {
      return (
        !this.localInputDateRange ||
        this.localInputDateRange?.start.length < 1 ||
        this.localInputDateRange?.end.length < 1
      );
    },
  },
};
</script>
