<template>
  <div>
    <div class="flex items-center">
      <label
        class="block font-medium text-sm text-gray mb-1"
        :class="{ 'text-error': error }"
      >
        {{ label }} <span v-if="mandatory" class="text-error">*</span>
      </label>
    </div>
    <textarea
      v-bind="$attrs"
      :disabled="$attrs.disabled"
      v-on="listeners"
      :value="value"
      @focus="isFocus = true"
      @blur="isFocus = false"
      class="border border-primary rounded-md p-3 w-full focus:ring-0 placeholder-gray-light"
      :class="[
        {
          'border-primary': !error,
          'border-error': error,
          'bg-gray-lightest border-none': disabled,
        },
        {
          'border-secondary shadow-secondary-round hover:shadow-secondary-round':
            isFocus,
        },
        { 'bg-gray-lightest': disabled },
        { 'border-primary hover:shadow-primary-sm ': !error && !disabled },
        { 'border-gray-lightest': disabled },
        { 'border-error hover:shadow-primary-sm': error },
      ]"
    >
    </textarea>
    <div
      v-if="this.$slots['message'] != undefined"
      class="text-xs"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
    <div
      v-show="countLabel && $attrs.maxlength && !this.$slots['message']"
      class="input-char-count text-secondary flex justify-between text-xs font-normal"
    >
      <p>Harap isi maksimal {{ $attrs.maxlength }} Karakter</p>
      <p>
        {{ value ? value.length : 0 }} /
        {{ $attrs.maxlength ? $attrs.maxlength : 0 }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    label: String,
    error: Boolean,
    disabled: Boolean,
    value: String,
    mandatory: Boolean,
    countLabel: {
      type: Boolean,
      default: false,
    },
  },

  name: "TextareaGista",

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
};
</script>
