<template>
  <div class="flex flex-col">
    <label
      class="block text-sm text-gray md:text-left font-medium"
      :class="{ 'text-error': isError, 'mb-1': label != '' }"
    >
      {{ label }}
      <span v-if="mandatory" class="text-red">*</span>
    </label>
    <div class="relative text-base w-full">
      <div
        class="flex items-center border rounded-lg"
        :class="[
          customClass,
          size == 'small'
            ? 'text-sm h-11'
            : size == 'medium'
            ? 'text-base h-12'
            : size == 'large'
            ? 'text-base h-12'
            : 'text-base h-12',
          isOpen
            ? 'border-secondary shadow-secondary-round'
            : disabled
            ? ''
            : 'border-primary hover:shadow-primary-sm',
        ]"
      >
        <!-- slot untuk icon left -->
        <div
          class="inset-y-0 items-center flex left-0 pl-2"
          v-if="this.$slots['icon-left'] != undefined"
          :class="[
            {
              'text-base': this.size == 'small',
              'text-2xl': this.size == 'medium',
              'text-2xl': this.size == 'large',
              'cursor-pointer':
                this.$slots['icon-left'] != undefined
                  ? this.$slots['icon-left'][0].data.on != undefined
                  : false,
            },
          ]"
        >
          <slot name="icon-left"></slot>
        </div>
        <!-- end-->
        <input
          v-bind="$attrs"
          type="text"
          v-model="value"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="[customClass]"
          @keyup="searchData"
          class="w-full border-none focus:ring-0 rounded-lg placeholder-gray-light text-gray"
          @focus="isOpen = !isOpen"
          @blur="closeOutside"
        />
        <div
          class="cursor-pointer"
          v-if="clear && value != '' && !disabled"
          @click="clearData"
        >
          <span class="icon-x-circle"></span>
        </div>
        <!-- @keyup="searchData" -->
        <div
          class="text-base pr-2 h-11 items-center flex inset-y-0 right-0 text-center"
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="isOpen ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
      <div
        v-show="isOpen"
        :class="`absolute left-0 right-0 mb-4 rounded-md overflow-auto bg-white shadow-primary-sm z-100 max-h-80 ${customWidth}`"
        style="max-height: 300px; overflow: auto"
        ref="scrollSearch"
        id="scrollSearch"
        @scroll="eventScroll"
      >
        <ul>
          <li
            class="px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white"
            v-if="options.length == 0 && !isLoading"
          >
            <slot name="emptyResult"> No item found </slot>
          </li>
          <li
            v-else
            v-for="(v, i) in options"
            :key="i"
            @click="setResult(v)"
            class="px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white cursor-pointer"
          >
            <slot name="option" :slot-props="v">
              {{ typeof v === "object" ? v[textField] : v }}
            </slot>
          </li>
          <li
            v-if="isLoading"
            class="px-3 py-2 transition-colors duration-100 text-sm text-center"
          >
            <slot name="loading"> Sedang Ambil Data... </slot>
          </li>
        </ul>
      </div>
      <div v-if="isError" class="text-error text-xs mt-1">
        <slot name="message"></slot>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
export default {
  name: "AutocompleteGistaEndles",
  props: {
    mandatory: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => "large",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => "",
    },
    value: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "Search here...",
    },
    options: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    isError: {
      type: Boolean,
      default: () => false,
    },
    serverSide: {
      type: Boolean,
      default: () => false,
    },
    textField: {
      type: String,
      default: () => "text",
    },
    valueField: {
      type: String,
      default: () => "id",
    },
    totalData: {
      type: Number,
      default: () => 0,
    },
    clear: {
      type: Boolean,
      default: () => false,
    },
    customWidth: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      results: null,
      timeout: null,
      search: "",
    };
  },
  mounted() {
    this.search = this.value;
  },
  computed: {
    // search() {
    //     return this.value
    // },
    cssEnabled() {
      return " bg-white";
    },
    cssDisabled() {
      return " border border-gray-lightest bg-gray-lightest text-gray-light cursor-not-allowed ";
    },
    cssError() {
      return " border-error ";
    },
    customClass() {
      let customClass = "";
      customClass += this.disabled ? this.cssDisabled : this.cssEnabled;
      customClass += this.isError ? this.cssError : "";
      return customClass;
    },
  },
  watch: {
    // value: _.debounce(function (a) {
    //   this.search = a;
    //   this.$emit("input", a);
    //   this.searchData()

    //   // if (this.search == "") {
    //   //   this.results = null;
    //   // }
    // }, 1000),
    value(a) {
      if (a == "") {
        this.results = null;
      }
      this.search = a;
      this.$emit("input", a);
      this.searchData();
    },
    isOpen() {
      this.$emit("clearData");
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (this.serverSide) {
          if (this.results == null) {
            this.$emit("callApi", false);
          } else {
            this.$emit("updateData", this.results);
          }
        }
      }, 500);
    },
  },
  methods: {
    searchData() {
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$emit("clearData");
        this.$emit("input", this.search);
        if (this.serverSide) {
          this.$emit("callApi", false);
        }
        if (this.search == "") {
          this.results = null;
        }
      }, 1000);
    },
    closeOutside() {
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$emit("clearData");
        this.isOpen = !this.isOpen;
        if (this.results == null) {
          // this.search = "";
          this.$emit("input", this.search);
        }
        if (this.search == "") {
          this.$emit("change");
        }
      }, 250);
    },

    eventScroll() {
      let container = this.$refs.scrollSearch;
      let totalHeightScroll = Math.floor(
        container.scrollTop + container.clientHeight + 5
      );

      // console.log(this.options.length, this.totalData)

      if (totalHeightScroll >= container.scrollHeight && !this.isLoading) {
        if (this.serverSide && !this.isLoading) {
          if (this.options.length < this.totalData) {
            this.$emit("callApi", true);
          }
        }
      }
    },

    setResult(value) {
      this.results = value;
      this.$emit("clearData");
      this.$emit("updateData", value);
      this.isOpen = !this.isOpen;
      this.$emit("change");
    },

    clearData() {
      this.$emit("input", "");
    },
  },
};
</script>

<style scoped>
#scrollSearch {
  scroll-behavior: smooth;
}

#scrollSearch::-webkit-scrollbar {
  width: 8px;
}

#scrollSearch::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #6de6dd;
}

#scrollSearch::-webkit-scrollbar-thumb:hover {
  background: #30d5c9;
}

#scrollSearch::-webkit-scrollbar-track {
  border-radius: 16px;
  background: #e3fffd;
}
</style>
