<template>
  <div>
    <div
      class="fixed inset-0 w-full flex items-center justify-center"
      v-if="show"
    ></div>
    <div
      class="absolute z-100 bg-white rounded-md p-4"
      v-if="show"
      :style="{
        width,
        top,
        left,
      }"
    >
      <div
        class="absolute z-90 w-4 h-4 transform rotate-45 bg-white -top-2 right-20"
      ></div>
      <slot></slot>
    </div>
    <div
      class="box-content absolute z-90 opacity-100"
      v-if="show"
      :style="[
        helper,
        {
          boxShadow: 'rgb(33 33 33 / 40%) 0px 0px 0px 5000px',
        },
      ]"
    ></div>
    <div
      class="box-content absolute z-100"
      v-if="show"
      :style="[helper, { backgroundColor: 'transparent' }]"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "",
    },
    left: {
      type: String,
      default: "",
    },
    top: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    helper: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "IntroGista",
};
</script>
