<template>
  <gista-modal
    :hide-close="false"
    :value="value"
    :fullScreen="false"
    @input="$emit('close')"
    :scrollable="true"
    width="2/3"
  >
    <template>
      <div class="py-8 text-gray bg-opacity-100">
        <div class="mx-8 mb-4 text-3xl font-bold text-gray-darkest">
          Detail Line Item
          {{ lineItemFormater(lineData.line_item) + " - " + lineData.coa }}
        </div>
        <div
          class="border-t-2 border-b-2 border-gray-lightest bg-primary-lightest bg-opacity-30 px-12 py-8 -mx-3 grid grid-cols-12 gap-4"
        >
          <div class="col-span-12 mb-4 gap-2">
            <div class="text-gray">Keterangan</div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.keterangan || "-" }}
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-gray">COA</div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.coa + "-" + lineData.sub_coa }}
            </div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.coa_description || "-" }}
            </div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.sub_coa_description || "-" }}
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-gray">Product Code</div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.product_code || "-" }}
            </div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.product_code_description || "-" }}
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-gray">Cost Center</div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.cost_center || "-" }}
            </div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.cost_center_description || "-" }}
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-gray">SLID</div>
            <div class="font-semibold text-gray-dark">
              {{ lineData.slid || "-" }}
            </div>
          </div>
          <div class="col-span-3">
            <div>
              <div class="text-gray">KMU GL</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.kmu_glm }}
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div>
              <div class="text-gray">Jumlah 1</div>
              <div class="font-semibold text-gray-dark">
                {{ currencyFormat(lineData.jumlah1_glm) }}
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div>
              <div class="text-gray">Jumlah 3</div>
              <div class="font-semibold text-gray-dark">
                {{ currencyFormat(lineData.jumlah3_glm) }}
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div>
              <div class="text-gray">Vendor</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.vendor || "-" }}
              </div>
              <div class="font-semibold">
                {{ lineData.nama_vendor || "-" }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="px-12 border-b-2 border-gray-lightest py-8 -mx-3 grid grid-cols-12 gap-3"
        >
          <div class="col-span-3">
            <div>
              <div class="text-gray">No. Dokumen Referensi</div>
              <div class="font-semibold text-gray-dark">
                {{
                  lineData.doc_referensi && lineData.doc_referensi_item
                    ? lineData.doc_referensi + "-" + lineData.doc_referensi_item
                    : `${lineData.doc_referensi || ""} ${
                        lineData.doc_referensi_item || ""
                      }`
                }}
                <div
                  v-if="!lineData.doc_referensi && !lineData.doc_referensi_item"
                >
                  -
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div>
              <div class="text-gray">PPN</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.kode_ppn || "" }} -
                {{ lineData.deskripsi_ppn || "" }}
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div>
              <div class="text-gray">PPH</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.kode_pph || "" }} -
                {{ lineData.jenis_npwp || "" }}
              </div>
              <div class="text-sm">
                {{ lineData.deskripsi_pph || "" }}
              </div>
            </div>
          </div>
          <div v-if="lineData.wbs" class="col-span-3">
            <div>
              <div class="text-gray">WBS</div>

              <div class="font-semibold text-gray-dark">
                <div>
                  {{ lineData.wbs || "-" }}
                </div>
                {{ lineData.wbs_description || "-" }}
              </div>
            </div>
          </div>
          <div
            v-else-if="lineData.cost_center_reimbursement"
            class="col-span-3"
          >
            <div>
              <div class="text-gray">RCC Reimbursement</div>
              <div class="font-semibold text-gray-dark">
                <div>
                  {{ lineData.cost_center_reimbursement || "-" }}
                </div>
                {{ lineData.cost_center_reimbursement_description }}
              </div>
            </div>
          </div>
          <div v-if="lineData.order_reimbursement" class="col-span-3">
            <div>
              <div class="text-gray">Order</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.order || "-" }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="lineData.coa_type && lineData.coa_type.includes('Penundaan')"
          class="border-b-2 border-gray-lightest bg-primary-lightest bg-opacity-30 px-12 py-8 -mx-3 grid grid-cols-12"
        >
          <div class="col-span-12 mb-4">
            <div class="font-semibold text-gray-dark">Penundaan Biaya</div>
          </div>
          <div class="col-span-4">
            <div>
              <div class="text-gray">Berlaku Dari</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.valid_from || "-" }}
              </div>
            </div>
          </div>
          <div class="col-span-4">
            <div>
              <div class="text-gray">Berlaku Sampai</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.valid_to || "-" }}
              </div>
            </div>
          </div>
          <div class="col-span-4">
            <div>
              <div class="text-gray">COA Akhir</div>
              <div class="font-semibold text-gray-dark">
                {{ lineData.coa_akhir || "-" }}
              </div>
            </div>
          </div>
        </div>
        <div class="mr-9" v-if="!hidden">
          <div class="flex items-center justify-end gap-6 mt-4">
            <div class="text-gray">Item</div>
            <input-gista
              v-model="page"
              name="page"
              placeholder=""
              data-vv-as="page"
              type="number"
              class="w-20"
            >
            </input-gista>
            <div class="text-gray">
              of {{ lineItemFormater(lineData.total_item) }}
            </div>
            <button-gista
              type="primary"
              :class="{
                'bg-gradient-tosca-blue button-gradient-transition': page != 1,
                'bg-primary button-gradient-transition': page == 1,
              }"
              :disabled="page == 1"
              id="display_journal-prev-page"
              @click="$emit('prevPage')"
            >
              &nbsp; Previous
              <template #icon-left>
                <div class="icon-arrow-left text-xl"></div>
              </template>
            </button-gista>
            <button-gista
              type="primary"
              :class="{
                'bg-gradient-tosca-blue button-gradient-transition':
                  page != lineData.total_item,
                'bg-primary button-gradient-transition':
                  page == lineData.total_item,
              }"
              :disabled="page == lineData.total_item"
              id="display_journal-next-page"
              @click="$emit('nextPage')"
            >
              Next &nbsp;
              <template #icon-right>
                <div class="icon-arrow-right icon-right text-xl"></div>
              </template>
            </button-gista>
          </div>
        </div>
      </div>
    </template>
  </gista-modal>
</template>

<script>
export default {
  name: "ModalDisplayDocument",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lineData: {
      type: Object,
      default: () => ({}),
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: "1",
      debounceTimer: null,
    };
  },
  watch: {
    lineData(value) {
      this.page = this.lineItemFormater(value.current_page);
    },
    page(value) {
      let newValue = this.lineItemFormater(value);
      if (newValue !== "0000") this.page = newValue;
      if (newValue.length > 4) this.page = newValue.substring(1);
      if (parseInt(newValue) === this.lineData.current_page) return;
      if (this.page !== this.lineData.current_page) {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
        if (parseInt(newValue) < "0001") {
          newValue = "0001";
        }
        this.debounceTimer = setTimeout(() => {
          this.$emit(
            "pageInput",
            parseInt(newValue) > this.lineData.total_item
              ? this.lineData.total_item
              : newValue
          );
        }, 1500);
      }
    },
  },
  methods: {
    lineItemFormater(lineItem) {
      const paddedNumber = String(lineItem).padStart(4, "0");
      return paddedNumber;
    },
    currencyFormat(number, withPrefix = false) {
      const val = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 8,
      }).format(number);

      return withPrefix ? val : val.replace("Rp", "").trim();
    },
  },
};
</script>
