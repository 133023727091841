<template>
  <ul>
    <li
      class="px-3 py-2 transition-colors duration-100 text-sm hover:bg-gray-lightest hover:text-gray-light"
      :value="value"
      :label="label"
      :class="isActive ? 'bg-secondary text-white' : 'text-gray-700'"
      @click="setOption(value, label)"
    >
      <slot />
    </li>
  </ul>
</template>

<script>
export default {
  // DON'T USE THIS COMPONENT
  props: {
    value: String,
    label: String,
    isActive: Boolean,
  },
  name: "DropdownItemGista",
  methods: {
    setOption(option, label) {
      this.$parent.selectItem(option, label);
    },
  },
};
</script>
