<template>
  <div
    class="flex flex-col gap-2 absolute z-30 bg-white rounded-b shadow-secondary-sm p-1"
  >
    <div class="flex items-center justify-between py-2">
      <button
        @click="onPrevYear"
        :disabled="disablePrevBtn"
        :class="
          disablePrevBtn
            ? 'bg-gray-lightest cursor-not-allowed'
            : 'bg-primary-lightest'
        "
        class="w-10 h-10 rounded flex items-center justify-center"
      >
        <span
          class="cursor-pointer icon-chevron-left text-gray text-2xl font-semibold"
          :class="{ 'cursor-not-allowed': disablePrevBtn }"
        ></span>
      </button>
      <span class="text-gray font-semibold cursor-pointer">
        {{ selectedYear }}
      </span>
      <button
        @click="onNextYear"
        class="w-10 h-10 rounded flex items-center justify-center"
        :disabled="disableNextBtn"
        :class="
          disableNextBtn
            ? 'bg-gray-lightest cursor-not-allowed'
            : 'bg-primary-lightest'
        "
      >
        <span
          class="cursor-pointer icon-chevron-right text-gray text-2xl font-semibold"
          :class="{ 'cursor-not-allowed': disableNextBtn }"
        ></span>
      </button>
    </div>
    <div class="grid grid-cols-3 gap-1">
      <button
        class="p-2 w-full border rounded flex items-center justify-center cursor-pointer month-item"
        :data-month="month.number"
        :class="{
          'bg-primary text-white': selectedMonth === month.number,
          'border-gray-lightest text-gray': selectedMonth !== month.number,
          'bg-gray-lightest text-gray-light cursor-not-allowed': month.disabled,
        }"
        v-for="(month, index) of months"
        :key="index"
        @click="onClickMonth(month)"
      >
        <span class="font-normal">{{ month.name }}</span>
      </button>
    </div>
    <div class="grid grid-cols-1 gap-1">
      <button
        class="p-2 w-full border rounded flex items-center justify-center cursor-pointer month-item bg-error text-white"
        :class="{
          'bg-error-light text-gray-light cursor-not-allowed': !selectedMonth,
        }"
        @click="resetMonth()"
      >
        <span class="font-normal">Reset</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Picker",
  props: {
    year: Number,
    month: Number,
    nextYear: Number,
    previousYear: Number,
  },
  data() {
    return {
      selectedYear: 0,
      selectedMonth: 0,
      startYear: this.getDate().endYear - this.previousYear,
      endYear: this.getDate().endYear + this.nextYear,
      years: [],
      months: [
        { name: "January", disabled: false, number: 1 },
        { name: "February", disabled: false, number: 2 },
        { name: "March", disabled: false, number: 3 },
        { name: "April", disabled: false, number: 4 },
        { name: "May", disabled: false, number: 5 },
        { name: "June", disabled: false, number: 6 },
        { name: "July", disabled: false, number: 7 },
        { name: "August", disabled: false, number: 8 },
        { name: "September", disabled: false, number: 9 },
        { name: "October", disabled: false, number: 10 },
        { name: "November", disabled: false, number: 11 },
        { name: "December", disabled: false, number: 12 },
      ],
      isToggleYear: false,
    };
  },
  computed: {
    disablePrevBtn() {
      return this.selectedYear === this.startYear;
    },
    disableNextBtn() {
      return this.selectedYear === this.endYear;
    },
  },
  watch: {
    selectedYear: {
      handler: function (value) {
        this.handlerDisableMonth(value);
      },
      deep: true,
    },
  },
  methods: {
    getDate() {
      const dateObject = new Date();
      return {
        year: dateObject.getFullYear(),
        month: dateObject.getMonth() + 1,
        endYear: dateObject.getFullYear(),
      };
    },
    generateListYear() {
      for (let year = this.startYear; year < this.endYear; year++) {
        this.years.push(year);
      }
    },
    setSelectedData() {
      this.selectedYear = this.year ? this.year : this.getDate().year;
      this.selectedMonth = this.month ? this.month : this.getDate().month;
    },
    onPrevYear() {
      if (this.selectedYear <= this.startYear) {
        return;
      }
      this.selectedYear--;
      this.changeValue();
    },
    onNextYear() {
      if (this.selectedYear >= this.endYear) {
        return;
      }
      this.selectedYear++;
      this.changeValue();
    },
    onClickMonth(month) {
      if (month.disabled) {
        return;
      }
      this.selectedMonth = month.number;
      this.changeValue();
    },
    changeValue() {
      this.$emit("change", {
        year: this.selectedYear,
        month: this.selectedMonth,
      });
    },
    handlerDisableMonth(selectedYear) {
      this.months.map((month) => {
        const currentDate = this.getDate();
        const selectedDate = {
          year: selectedYear,
          month: month.number,
        };
        const monthDiff =
          (currentDate.year - selectedDate.year) * 12 +
          (currentDate.month - selectedDate.month);
        // Disable the month if it is outside the last 12 months
        month.disabled =
          monthDiff > 12 * this.previousYear || monthDiff < -12 * this.nextYear;
      });
      this.resetMonth();
    },
    resetMonth() {
      this.selectedMonth = 0;
      this.$emit("reset");
    },
  },
  mounted() {
    this.generateListYear();
    this.setSelectedData();
    this.changeValue();
  },
};
</script>
