<template>
  <div class="flex flex-col">
    <label
      v-if="label"
      style="white-space: normal"
      class="block text-sm text-gray font-medium mb-1"
      :class="{ 'text-error': error }"
    >
      {{ label }} <span v-if="mandatory" class="text-error">*</span>
    </label>
    <div class="relative text-sm w-full h-full">
      <button
        :title="
          selectedFilterStatus.toString().length > 0
            ? selectedFilterStatus.toString()
            : ''
        "
        class="flex items-center justify-between px-3 py-2.5 h-12 w-full text-lg border rounded-md"
        :class="[
          {
            'rounded-md': customClass === '',
            'bg-gray-lightest text-gray-darkest cursor-not-allowed': disabled,
            'text-gray-lightest': disabled,
            'bg-white border-primary focus:shadow-secondary-lg focus:border-secondary':
              !disabled && !noBorder,
            'border-error': error,
            'hover:shadow-primary-sm': !noBorder && !disabled,
            'text-base': size !== 'large',
            'h-11': size === 'large',
          },
          customClass,
        ]"
        @click="toggleOptions"
        @blur="checkOptionExpanded"
      >
        <span
          class="text-base"
          :class="{
            'pl-6': iconLeft,
            'cursor-not-allowed': disabled,
            'text-gray-light':
              selectedFilterStatus.length === 0 ||
              selectedFilterStatus === undefined,
            'text-gray': selectedFilterStatus.length !== 0,
          }"
        >
          {{ shownStatusValue(selected, placeholder) }}
        </span>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
          :class="!readOnly && isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <transition
        enter-active-class="transform transition duration-500 ease-custom"
        enter-from-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-300 ease-custom"
        leave-from-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <ul
          v-show="!readOnly && isOptionsExpanded"
          class="absolute left-0 right-0 mb-4 rounded-md overflow-hidden bg-white z-50"
          :class="{
            'shadow-primary-sm':
              !disabled && (color == undefined || color == 'primary'),
            'shadow-secondary-sm': !disabled && color == 'secondary',
            'shadow-tertiary-sm': !disabled && color == 'tertiary',
          }"
        >
          <li
            class="px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white"
            :class="allState ? 'bg-secondary text-white' : 'text-gray'"
          >
            <label
              class="flex justify-between"
              @focus="selectFocus"
              @blur="
                isModeSelection = false;
                checkOptionExpanded();
              "
            >
              <slot name="option"> Semua </slot>

              <input
                type="checkbox"
                v-model="allState"
                @focus="selectFocus"
                @change="onAllCheckboxChanged"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @blur="
                  isModeSelection = false;
                  checkOptionExpanded();
                "
              />
            </label>
          </li>
          <li
            v-for="(v, i) in options"
            :key="i"
            class="px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white"
            :selectedFilterStatus="v"
            :label="label"
            :class="isActive(v) ? 'bg-secondary text-white' : 'text-gray'"
          >
            <label
              class="flex justify-between"
              @focus="selectFocus"
              @blur="
                isModeSelection = false;
                checkOptionExpanded();
              "
            >
              <slot name="option" :slot-props="v">
                {{ v }}
              </slot>

              <input
                type="checkbox"
                v-model="selectedFilterStatus"
                :value="v"
                @focus="selectFocus"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @click="setOption(v, i, $event)"
                @blur="
                  isModeSelection = false;
                  checkOptionExpanded();
                "
              />
            </label>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>
<script>
/*
  <select-multiple-gista 
    :label="'Status'" 
    :options="statusOptions" 
    //props options yg dikirim selain 'ALL'
    v-model="selectedFilterStatus" 
    class="w-full">
  </select-multiple-gista>
*/
import { timer } from "rxjs";

export default {
  name: "SelectMultipleGista",
  props: {
    error: {
      // error flag
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    label: String,
    iconLeft: String,
    options: Array,
    customClass: String,
    color: String,
    noBorder: {
      // prefer not to use this
      type: Boolean,
      default: false,
    },
    size: {
      // prefer not to use this
      type: String,
      default: "small",
    },
    value: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: "Select Filter",
    },
    mandatory: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOptionsExpanded: false,
      selected: "",
      allState: false,
      isModeSelection: false,
      selectedFilterStatus: [],
    };
  },
  watch: {
    selectedFilterStatus: {
      handler(v) {
        this.selected = v;
      },
      immediate: true,
    },
    value: {
      handler(v) {
        if (v.length !== this.options.length) {
          this.allState = false;
        }

        this.selectedFilterStatus = v;
      },
      immediate: true,
    },
  },
  methods: {
    onAllCheckboxChanged(event) {
      if (event.target.checked) {
        for (const element of this.options) {
          this.setSelectedStatus(element);
        }
      } else {
        for (const element of this.options) {
          this.removeFilterStatus(element);
        }
      }
    },
    setSelectedStatus(filter) {
      if (
        this.selectedFilterStatus.findIndex((status) => status === filter) < 0
      ) {
        this.selectedFilterStatus.push(filter);
      }

      // jika filter terpilih semua ubah state all ke true
      if (this.selectedFilterStatus.length === this.options.length)
        this.allState = true;
      // jika filter tidak terpilih semua ubah state all ke false
      else if (this.selectedFilterStatus.length < this.options.length) {
        this.allState = false;
      }
    },
    removeFilterStatus(filter) {
      const index = this.selectedFilterStatus.findIndex(
        (status) => status === filter
      );
      if (index > -1) this.selectedFilterStatus.splice(index, 1);

      // Remove semua status
      this.allState = false;
    },
    shownStatusValue(status, placeholder) {
      if (this.allState) {
        return `Semua ${this.label}`;
      } else if (status.length > 1) {
        return `${status.length} ${this.label} terpilih`;
      }
      return status.toString().replaceAll(",", ", ") || placeholder + " ";
    },
    setOption(v, i, event) {
      const isChecked = event.target.checked;
      if (isChecked) {
        this.selected = v;
        this.setSelectedStatus(v);
      } else {
        this.removeFilterStatus(v);
      }
      this.$emit("input", this.selectedFilterStatus);
    },
    toggleOptions() {
      this.isOptionsExpanded = this.disabled ? false : !this.isOptionsExpanded;
    },
    isActive(option) {
      if (this.selectedFilterStatus.includes(option)) return true;
      return false;
    },
    checkOptionExpanded() {
      timer(200).subscribe(() => {
        if (!this.isModeSelection) this.isOptionsExpanded = false;
      });
    },
    selectFocus() {
      this.isModeSelection = true;
    },
  },
};
</script>
