var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"flex items-center text-sm text-gray truncate font-medium",class:{ 'text-error': _vm.error, 'mb-1': _vm.label }},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.mandatory)?_c('span',{staticClass:"text-error"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"relative text-sm w-full h-full"},[_c('div',_vm._b({directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"flex items-center justify-between px-3 py-2 w-full text-lg rounded-md border cursor-pointer",class:[
        {
          'bg-gray-lightest text-gray-light cursor-not-allowed': _vm.disabled,
          'text-gray-lightest': _vm.disabled,
          'bg-white border-primary focus:shadow-secondary-round focus:border-secondary':
            !_vm.disabled && !_vm.noBorder,
          'border-error': _vm.error,
          'hover:shadow-primary-sm': !_vm.noBorder && !_vm.disabled,
          'text-small h-11': _vm.size == 'small',
          'text-base h-12': _vm.size == 'medium',
          'text-base h-14': _vm.size == 'large',
        },
        _vm.customClass ],on:{"click":_vm.toggleOptions}},'div',_vm.$attrs,false),[(_vm.iconLeft)?_c('div',{staticClass:"absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"},[_c('span',{class:_vm.iconLeft})]):_vm._e(),_c('span',{staticClass:"text-base",class:{
          'pl-6': _vm.iconLeft,
          'cursor-not-allowed text-gray-light': _vm.disabled,
          'text-gray-light':
            (typeof _vm.value === 'string' && _vm.value === '') ||
            (typeof _vm.value === 'object' && _vm.value && _vm.value.value === '') ||
            _vm.value === undefined,
          'text-gray': _vm.value !== '' && !_vm.disabled,
        }},[_vm._t("selected",function(){return [_vm._v(_vm._s(_vm.value ? typeof _vm.value === "object" ? _vm.value.text : _vm.value : _vm.selected ? typeof _vm.selected === "object" ? _vm.selected.text : _vm.selected : _vm.placeholder))]},{"slotProps":_vm.selected})],2),_c('div',{staticClass:"flex items-center"},[(_vm.value && _vm.clearable)?_c('div',{staticClass:"mr-2 icon-x z-10",on:{"click":function($event){$event.stopPropagation();return _vm.clear.apply(null, arguments)}}}):_vm._e(),_c('svg',{staticClass:"h-4 w-4 transform transition-transform duration-200 ease-in-out",class:!_vm.readOnly && _vm.isOptionsExpanded ? 'rotate-180' : 'rotate-0',attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])]),_c('transition',{attrs:{"enter-active-class":"transform transition duration-500 ease-custom","enter-class":"-translate-y-1/2 scale-y-0 opacity-0","enter-to-class":"translate-y-0 scale-y-100 opacity-100","leave-active-class":"transform transition duration-300 ease-custom","leave-class":"translate-y-0 scale-y-100 opacity-100","leave-to-class":"-translate-y-1/2 scale-y-0 opacity-0"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readOnly && _vm.isOptionsExpanded),expression:"!readOnly && isOptionsExpanded"}],staticClass:"absolute left-0 right-0 mb-4 rounded-md overflow-auto max-h-52 bg-white z-50 shadow-primary-sm"},_vm._l((_vm.options),function(v,i){return _c('li',{key:i,staticClass:"px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white",class:_vm.isActive(v)
              ? 'bg-secondary-light text-secondary-darkest font-medium'
              : 'text-gray',attrs:{"value":typeof v === 'object' && v ? v.value : v,"label":_vm.label},on:{"click":function($event){return _vm.setOption(v)}}},[_vm._t("option",function(){return [_vm._v(_vm._s(typeof v === "object" && v ? v.text : v))]},{"slotProps":v})],2)}),0)]),(_vm.hint && !_vm.dense)?_c('div',{staticClass:"text-xs text-gray mt-1"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e(),(this.$slots['message'] != undefined)?_c('div',{staticClass:"text-xs",class:[{ 'text-error': _vm.error }]},[_vm._t("message")],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }