<template>
  <div class="flex items-center">
    <input
      :id="idName"
      v-bind="$attrs"
      type="radio"
      :name="name"
      class="checked:bg-primary focus:ring-white text-primary"
      :class="{
        'h-6 w-6 text-base': size == 'md',
        'h-8 w-8 text-lg': size == 'lg',
        'h-10 w-10 text-xl': size == 'xl',
        'border border-primary hover:shadow-primary-sm cursor-pointer':
          !disabled,
        'cursor-not-allowed': disabled || readOnly,
        'text-primary-lightest': disabled && isChecked,
        'border border-gray-lightest text-gray-lightest bg-gray-lightest':
          disabled && !isChecked,
      }"
      :disabled="disabled || readOnly"
      :checked="isChecked"
      :value="nativeValue"
      :readonly="readOnly"
      @change="!readOnly && updateValue($event.target.value)"
    />
    <span
      class="ml-2"
      :class="{
        'text-base': size == 'md',
        'text-lg': size == 'lg',
        'text-xl': size == 'xl',
      }"
      ><slot></slot
    ></span>
  </div>
</template>

<script>
/*
<radio-gista name="radio" native-value="satu" v-model="radio" size="md">
      Satu
    </radio-gista>

    <radio-gista name="radio" native-value="dua" v-model="radio" size="md">
      Dua
    </radio-gista>
*/

export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    idName: String,
    size: {
      // md text-base, lg text-lg, xl text-xl
      type: String,
      default: "md",
    },
    name: {
      type: String,
    },
    modelValue: {
      default: "",
    },
    nativeValue: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  name: "RadioGista",
  computed: {
    isChecked() {
      return this.modelValue == this.nativeValue;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>
