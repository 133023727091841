var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block text-sm text-gray font-medium",class:{ 'text-error': _vm.error, 'mb-1': _vm.label, truncate: _vm.truncateLabel },staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.mandatory)?_c('span',{staticClass:"text-error"},[_vm._v("*")]):_vm._e()])]),_c('div',{staticClass:"pl-0 rounded-lg border items-center",class:[
      {
        ' grid grid-cols-4 border':
          this.$slots['select-item-left'] != undefined ||
          this.$slots['select-item-right'],
      },
      {
        flex:
          this.$slots['select-item-left'] == undefined ||
          this.$slots['select-item-right'] == undefined,
      },
      {
        'border-secondary shadow-secondary-round hover:shadow-secondary-round':
          _vm.isFocus,
      },
      { 'bg-gray-lightest': _vm.disabled },
      {
        'border-primary hover:shadow-primary-sm ': !_vm.error && !_vm.disabled,
      },
      { 'border-gray-lightest': _vm.disabled },
      { 'border-error hover:shadow-primary-sm': _vm.error },
      {
        'text-small h-11': this.size == 'small',
        'text-base h-12': this.size == 'medium',
        'text-base h-14': this.size == 'large',
      } ]},[(this.$slots['icon-left'] != undefined)?_c('div',{staticClass:"inset-y-0 items-center flex left-0 pl-2",class:[
        {
          'text-base': this.size == 'small',
          'text-2xl': this.size == 'medium',
          'text-2xl': this.size == 'large',
          'cursor-pointer':
            this.$slots['icon-left'] != undefined
              ? this.$slots['icon-left'][0].data.on != undefined
              : false,
        } ]},[_vm._t("icon-left")],2):_vm._e(),(this.$slots['select-item-left'] != undefined)?_c('div',{staticClass:"inset-y-0 items-center left-0 pl-0 w-full col-span-1",class:[
        {
          'text-base': this.size == 'small',
          'text-2xl': this.size == 'medium',
          'text-2xl': this.size == 'large',
        } ]},[_vm._t("select-item-left")],2):_vm._e(),_c('input',_vm._g(_vm._b({staticClass:"w-full border-none focus:ring-0 h-full rounded-lg placeholder-gray-light text-gray",class:[
        {
          'bg-gray-lightest text-gray-light': _vm.disabled,
        },
        { 'cursor-not-allowed': _vm.disabled || _vm.$attrs.readonly },
        { 'col-span-2': this.$slots['select-item'] != undefined } ],attrs:{"id":_vm.idName,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false},"keypress":_vm.disabledWhiteSpace,"keydown":_vm.disabledWhiteSpace}},'input',_vm.$attrs,false),_vm.listeners)),(this.$slots['icon-right'] != undefined)?_c('div',{staticClass:"text-base pr-2 items-center flex inset-y-0 right-0 text-center",class:[
        {
          'text-2xl':
            this.$slots['icon-right'] != undefined
              ? this.$slots['icon-right'][0].data.class != undefined
                ? true
                : false
              : false,
          'cursor-pointer':
            this.$slots['icon-right'] != undefined
              ? this.$slots['icon-right'][0].data.on != undefined
              : false,
          'bg-primary text-white rounded-r-lg w-28':
            this.$slots['icon-right'] != undefined
              ? this.$slots['icon-right'][0].children != undefined
                ? true
                : false
              : false,
        },
        {
          'h-11': this.size == 'small',
          'h-12': this.size == 'medium',
          'h-14': this.size == 'large',
        } ]},[_vm._t("icon-right")],2):_vm._e(),(this.$slots['select-item-right'] != undefined)?_c('div',{staticClass:"inset-y-0 items-center border-border-none right-0 pr-0 w-full col-span-1",class:[
        {
          'text-2xl': this.size == 'small',
          'text-2xl': this.size == 'large',
        } ]},[_vm._t("select-item-right")],2):_vm._e()]),(this.$slots['message'] != undefined)?_c('div',{staticClass:"text-xs mt-1",class:[{ 'text-error': _vm.error }]},[_vm._t("message")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }