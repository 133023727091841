<template>
  <div>
    <gista-modal
      :hide-close="false"
      :value="value"
      :fullScreen="false"
      @input="$emit('closeModal')"
      :scrollable="true"
      width="3/4"
    >
      <template>
        <card class="mt-6 mb-3">
          <div
            class="text-2xl font-semibold border-b-2 border-gray-lightest pb-3 w-full"
          >
            Display Jurnal
          </div>
          <div class="my-3">
            <div class="text-base text-gray">Nomor Dokumen</div>
            <div class="text-base font-semibold text-gray-dark">
              {{ headerData.no_document || "-" }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-3">
              <div class="text-base text-gray">Tanggal Jurnal</div>
              <div class="text-base font-semibold text-gray-dark">
                {{ headerData.tanggal_jurnal || "-" }}
              </div>
            </div>
            <div class="col-span-3">
              <div class="text-base text-gray">User Pembuat</div>
              <div class="text-base font-semibold text-gray-dark">
                {{ headerData.user_pembuat || "-" }}
              </div>
            </div>
            <div class="col-span-3">
              <div class="text-base text-gray">No. Reverse</div>
              <div class="text-base font-semibold text-gray-dark">
                {{ headerData.no_reverse || "-" }}
              </div>
            </div>
            <div class="col-span-3">
              <div class="text-base text-gray">RCC Pembuat</div>
              <div class="text-base font-semibold text-gray-dark">
                {{ headerData.user_approve || "-" }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="text-base text-gray">Keterangan</div>
            <div class="text-base font-semibold text-gray-dark">
              {{ headerData.keterangan || "-" }}
            </div>
          </div>
        </card>

        <card v-if="detailData">
          <TableDisplayJurnal
            :dataTable="detailData"
            :no_document="headerData.no_document"
            @fetchLineData="fetchLineData"
            @fetchDetailData="fetchDetailData"
          ></TableDisplayJurnal>
        </card>
      </template>
    </gista-modal>
    <DetailJournalItem
      :value="isOpenLineModal"
      @close="closeLineModal"
      @onBack="closeLineModal"
      @prevPage="prevPage"
      @nextPage="nextPage"
      @pageInput="pageInput"
      :lineData="formatedLineData"
    ></DetailJournalItem>
  </div>
</template>
<script>
/*
  <display-journal-gista
    :value="isOpenModal"                                | local variable
    :isOpenLineModal="isOpenLineModal"                  | local variable
    :headerData="headerData"                            | local variable
    :detailData="detailData"                            | local variable
    :lineData="lineData"                                | local variable
    @fetchDetailData="fetchDetailData"              | local function
    @fetchLineData="fetchLineData"          | local function
    @closeLineModal="isOpenLineModal = !isOpenLineModal"
    @closeModal="isOpenModal = !isOpenModal">
  </display-journal-gista>
*/
import TableDisplayJurnal from "./components/TableDisplayJournal.vue";
import DetailJournalItem from "./components/DetailJournalItem.vue";
export default {
  name: "DisplayJournalGista",
  components: {
    TableDisplayJurnal,
    DetailJournalItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    headerData: {
      type: Object,
      default: () => ({}),
    },
    detailData: {
      type: Object,
      default: () => ({}),
    },
    lineData: {
      type: Object,
      default: () => ({}),
    },
    isOpenLineModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberIndex: 1,
      filter: {},
      tableRequest: {},
    };
  },
  computed: {
    formatedLineData() {
      return {
        ...this.lineData,
        current_page: this.numberIndex,
      };
    },
  },
  methods: {
    closeLineModal() {
      this.$emit("closeLineModal");
    },
    prevPage() {
      this.fetchLineData(this.numberIndex - 1);
    },
    nextPage() {
      this.fetchLineData(this.numberIndex + 1);
    },
    pageInput(value) {
      this.fetchLineData(value);
    },
    fetchDetailData(filter, tableRequest, options) {
      if (filter) this.filter = filter;
      if (tableRequest) this.tableRequest = tableRequest;
      let payload = {
        page: options.page,
        size: options.itemsPerPage,
        debit: this.filter.debit,
        credit: this.filter.credit,
        idr: this.filter.idr,
        valas: this.filter.valas,
        rak_c: this.filter.rak_c,
        sort: this.tableRequest.sortOrder.map(
          (sort) => `${sort.field},${sort.direction}`
        ),
      };
      if (this.filter.coa) {
        payload = { ...payload, coa: this.filter.coa };
      }
      if (this.filter.slid) {
        payload = { ...payload, slid: this.filter.slid.toLowerCase() };
      }
      if (this.filter.sub_coa) {
        payload = { ...payload, sub_coa: this.filter.sub_coa };
      }
      if (this.filter.cost_center) {
        payload = {
          ...payload,
          cost_center: this.filter.cost_center.toLowerCase(),
        };
      }
      if (this.filter.product_code) {
        payload = {
          ...payload,
          product_code: this.filter.product_code.toLowerCase(),
        };
      }
      if (this.filter.no_referensi) {
        payload = {
          ...payload,
          no_referensi: this.filter.no_referensi.toLowerCase(),
        };
      }
      this.$emit("fetchDetailData", payload);
    },
    lineItemFormater(lineItem) {
      const paddedNumber = String(lineItem).padStart(4, "0");
      return paddedNumber;
    },
    fetchLineData(lineItem) {
      this.numberIndex = parseInt(lineItem);
      let payload = {
        line_item: this.lineItemFormater(lineItem),
      };
      this.$emit("fetchLineData", payload);
    },
  },
};
</script>
<style lang=""></style>
