<template>
  <div>
    <div class="border-b-2 border-gray-lightest pb-3 mb-3 bg-opacity-100">
      <!-- Title and Button control -->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold">{{ no_document }}</div>
        <div class="flex gap-4">
          <button-gista
            type="secondary"
            @click="clickFilter"
            id="display_journal-open-close-filter"
            >{{ openFilter ? "Hide Filter" : "Filter" }} &nbsp;
            <template #icon-right>
              <span
                :class="{
                  'icon-chevron-down text-xl': !openFilter,
                  'icon-chevron-up text-xl': openFilter,
                }"
              ></span>
            </template>
          </button-gista>
        </div>
      </div>

      <div :class="{ block: openFilter, hidden: !openFilter }">
        <div class="mt-4 grid grid-cols-12 gap-8">
          <div class="col-span-3">
            <div class="text-gray">D/C</div>
            <div class="flex gap-6 items-center">
              <gista-checkbox class="text-gray" v-model="filter.debit"
                >DB</gista-checkbox
              >
              <gista-checkbox class="text-gray" v-model="filter.credit"
                >CR</gista-checkbox
              >
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-gray">KMU GL</div>
            <div class="flex gap-6 items-center">
              <gista-checkbox class="text-gray" v-model="filter.idr"
                >IDR</gista-checkbox
              >
              <gista-checkbox class="text-gray" v-model="filter.valas"
                >Valas</gista-checkbox
              >
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-gray">Rak C</div>
            <div class="mt-2">
              <switch-gista v-model="filter.rak_c" class="text-gray">
                {{ filter.rak_c ? "Ya" : "Tidak" }}
              </switch-gista>
            </div>
          </div>
          <div class="col-span-3">
            <input-gista
              v-model="filter.slid"
              label="SLID"
              name="slid"
              placeholder="Input SLID"
              data-vv-as="Search SLID"
              type="text"
              class="w-full rounded-none"
            >
            </input-gista>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-8 my-3">
          <div class="col-span-3">
            <input-gista
              v-model="filter.coa"
              label="COA"
              name="coa"
              placeholder="Input COA"
              data-vv-as="Search COA"
              type="text"
              class="w-full rounded-none"
            >
            </input-gista>
          </div>
          <div class="col-span-3">
            <input-gista
              v-model="filter.sub_coa"
              label="Sub COA"
              name="subcoa"
              placeholder="Input Sub COA"
              data-vv-as="Sub COA"
              type="text"
              class="w-full rounded-none"
            >
            </input-gista>
          </div>
          <div class="col-span-3">
            <input-gista
              v-model="filter.cost_center"
              label="Cost Center"
              name="cost"
              placeholder="Input Cost Center"
              data-vv-as="Cost Center"
              type="text"
              class="w-full rounded-none"
            >
            </input-gista>
          </div>
          <div class="col-span-3">
            <input-gista
              v-model="filter.product_code"
              label="Product Code"
              name="product"
              placeholder="Input Product Code"
              data-vv-as="Product Code"
              type="text"
              class="w-full rounded-none"
            >
            </input-gista>
          </div>
          <div class="col-span-3">
            <input-gista
              v-model="filter.no_referensi"
              label="No. Dokumen Referensi"
              name="product"
              placeholder="Input No. Dokumen Referensi"
              data-vv-as="Search Referensi"
              type="text"
              class="w-full rounded-none"
            >
            </input-gista>
          </div>
        </div>

        <div class="flex gap-4 mt-3 justify-end">
          <button-gista
            type="secondary"
            @click="resetFilter"
            :disabled="isFilterButtonLock"
            id="display_journal-reset-filter"
            >Reset
          </button-gista>
          <button-gista
            type="primary"
            :class="{
              'bg-gradient-tosca-blue button-gradient-transition':
                !isFilterButtonLock,
            }"
            @click="applyFilter"
            :disabled="isFilterButtonLock"
            id="display_journal-apply-filter"
            >Apply Filter</button-gista
          >
        </div>
      </div>
    </div>

    <div class="overflow-x-auto">
      <table-gista
        :headers="headersTable"
        :sortOrder.sync="tableRequest.sortOrder"
        :items="dataTable.content"
        :options.sync="options"
        :searchable="false"
        :serverSide="true"
        :showPagination="true"
        :notFoundLabel="'Data tidak ditemukan'"
        :scrollX="false"
        :tdClass="'py-2'"
      >
        <template v-slot:line_item="{ slotProps }">
          <div
            class="underline text-secondary cursor-pointer"
            @click="
              $emit(
                'fetchLineData',
                slotProps.data.line_item,
                filter,
                tableRequest
              )
            "
          >
            {{ lineItemFormater(slotProps.data.line_item) }}
          </div>
        </template>
        <template v-slot:coa="{ slotProps }">
          <div>
            <div>{{ slotProps.data.coa }}</div>
            <div class="text-xs">{{ slotProps.data.coa_description }}</div>
          </div>
        </template>
        <template v-slot:sub_coa="{ slotProps }">
          <div>
            <div>{{ slotProps.data.sub_coa }}</div>
            <div class="text-xs">{{ slotProps.data.sub_coa_description }}</div>
          </div>
        </template>
        <template v-slot:product_code="{ slotProps }">
          <div>
            <div>{{ slotProps.data.product_code }}</div>
            <div class="text-xs">
              {{ slotProps.data.product_code_description }}
            </div>
          </div>
        </template>
        <template v-slot:cost_center="{ slotProps }">
          <div>
            <div>{{ slotProps.data.cost_center }}</div>
            <div class="text-xs">
              {{ slotProps.data.cost_center_description }}
            </div>
          </div>
        </template>
        <template v-slot:jumlah1_glm="{ slotProps }">
          <div v-if="slotProps.data.type === 'DB'">
            <div v-if="slotProps.data.kmu_glm === 'IDR'">
              {{ currencyFormat(slotProps.data.jumlah1_glm, false) }}
            </div>
            <div v-else>
              {{ currencyFormat(slotProps.data.jumlah1_glm, false) }}
            </div>
          </div>
          <div v-else>
            <div v-if="slotProps.data.kmu_glm === 'IDR'">
              ({{ currencyFormat(slotProps.data.jumlah1_glm, false) }})
            </div>
            <div v-else>
              ({{ currencyFormat(slotProps.data.jumlah1_glm, false) }})
            </div>
          </div>
        </template>
        <template v-slot:jumlah3_glm="{ slotProps }">
          <div v-if="slotProps.data.type === 'DB'">
            <div v-if="slotProps.data.kmu_glm === 'IDR'">
              {{ currencyFormat(slotProps.data.jumlah3_glm, false) }}
            </div>
            <div v-else>
              {{ currencyFormat(slotProps.data.jumlah3_glm, false) }}
            </div>
          </div>
          <div v-else>
            <div v-if="slotProps.data.kmu_glm === 'IDR'">
              ({{ currencyFormat(slotProps.data.jumlah3_glm, false) }})
            </div>
            <div v-else>
              ({{ currencyFormat(slotProps.data.jumlah3_glm, false) }})
            </div>
          </div>
        </template>
        <template v-slot:comment="{ slotProps }">
          <div>
            {{ formatingComment(slotProps.data.comment) }}
          </div>
        </template>
        <template v-slot:keterangan="{ slotProps }">
          <div :class="wrapwordClass(slotProps.data.keterangan)">
            {{ slotProps.data.keterangan }}
          </div>
        </template>
      </table-gista>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableDisplayJurnal",
  props: {
    no_document: {
      type: String,
      default: "",
    },
    dataTable: {
      type: Object,
    },
  },
  data() {
    return {
      filter: {
        debit: true,
        credit: true,
        idr: true,
        valas: true,
        rak_c: true,
        slid: "",
        coa: "",
        sub_coa: "",
        cost_center: "",
        product_code: "",
        no_referensi: "",
      },
      headersTable: [
        {
          text: "Line Item",
          value: "line_item",
          align: "start",
          sortable: true,
        },
        {
          text: "D/C",
          value: "type",
          align: "start",
          sortable: true,
        },
        {
          text: "COA",
          value: "coa",
          align: "start",
          sortable: true,
          width: "w-44",
        },
        {
          text: "Sub COA",
          value: "sub_coa",
          align: "start",
          sortable: true,
          width: "w-44",
        },
        {
          text: "Product Code",
          value: "product_code",
          align: "start",
          sortable: true,
        },
        {
          text: "Cost Center",
          value: "cost_center",
          align: "start",
          sortable: true,
          width: "w-44",
        },
        {
          text: "KMU GL",
          value: "kmu_glm",
          align: "start",
          sortable: true,
        },
        {
          text: "Jumlah 3",
          value: "jumlah3_glm",
          align: "end",
          sortable: true,
          width: "w-44",
        },
        {
          text: "Jumlah 1 (IDR)",
          value: "jumlah1_glm",
          align: "end",
          sortable: true,
          width: "w-44",
        },
        {
          text: "SLID",
          value: "slid",
          align: "start",
          sortable: true,
        },
        {
          text: "No. Dokumen Referensi",
          value: "doc_referensi",
          align: "start",
          sortable: true,
        },
        {
          text: "Keterangan",
          value: "keterangan",
          align: "start",
          sortable: true,
          width: "w-64",
        },
      ],
      tableRequest: {
        currentPage: 1,
        sortOrder: [],
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      openFilter: false,
      isFilterButtonLock: true,
    };
  },
  created() {
    this.options.totalItems = this.dataTable.total_elements;
  },
  watch: {
    tableRequest: {
      handler() {
        this.options.page = 1;
        this.fetchDetailData();
      },
      deep: true,
    },
    dataTable: {
      handler(value) {
        this.options.totalItems = value.total_elements;
      },
    },
    filter: {
      handler() {
        this.isFilterButtonLock = false;
      },
      deep: true,
    },
    "filter.coa": {
      handler(val) {
        const cleanedVal = val.replace(/[^\d]/g, "");
        this.filter.coa = cleanedVal;
      },
    },
    "options.page": "fetchDetailData",
    "options.itemsPerPage": "fetchDetailData",
  },
  methods: {
    clickFilter() {
      this.openFilter = !this.openFilter;
    },
    lineItemFormater(lineItem) {
      const paddedNumber = String(lineItem).padStart(4, "0");
      return paddedNumber;
    },
    currencyFormat(number, withPrefix = false) {
      const val = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 8,
      }).format(number);

      return withPrefix ? val : val.replace("Rp", "").trim();
    },
    wrapwordClass(value) {
      if (!value) {
        return;
      }
      const words = value?.split(" ");
      let anyWordExceedsLength = false;
      const classes = {};

      words?.forEach((word) => {
        if (word?.length > 30) {
          anyWordExceedsLength = true;
          classes[`wrapword`] = true;
        }
      });

      if (!anyWordExceedsLength) {
        classes["notbreakword"] = true;
      }
      return classes;
    },
    formatingComment(value) {
      if (value) return value.length < 150 ? value : value.slice(0, 148) + "..";
    },
    applyFilter() {
      this.options = {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      };
      this.fetchDetailData();
    },
    fetchDetailData() {
      this.$emit(
        "fetchDetailData",
        this.filter,
        this.tableRequest,
        this.options
      );
    },
    async resetFilter() {
      this.filter = {
        debit: true,
        credit: true,
        idr: true,
        valas: true,
        rak_c: true,
        slid: "",
        coa: "",
        sub_coa: "",
        cost_center: "",
        product_code: "",
        no_referensi: "",
      };
      await this.fetchDetailData();
      this.isFilterButtonLock = true;
    },
  },
};
</script>
