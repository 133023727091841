<template>
  <transition name="fade">
    <div
      class="flex flex-col items-start p-6 bg-white rounded-md"
      :class="{
        'shadow-primary': !flat,
        'border border-gray-lightest': flat,
      }"
    >
      <div
        @click="expanded = !expanded"
        class="flex justify-between items-start w-full cursor-pointer"
      >
        <div class="flex-initial">
          <slot name="title"></slot>
        </div>
        <button
          class="rounded-full hover:bg-gray-lightest duration-200 flex items-center p-3 font-bold text-lg"
        >
          <span
            :class="expanded ? 'icon-chevron-up' : 'icon-chevron-down'"
          ></span>
        </button>
      </div>
      <div class="overflow-hidden w-full">
        <transition name="expand">
          <template>
            <div
              v-show="expanded"
              class="flex flex-col border-solid border-t-gray-primary"
            >
              <slot></slot>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
// <expansion-panel-gista title="Database Gedung">
//   <div slot="title">hello</div>
//   <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur maxime libero aliquam deleniti harum, repudiandae, excepturi nihil sequi officiis mollitia exercitationem ipsa numquam odit eaque facilis molestiae consequatur ratione cumque.</div>
// </expansion-panel-gista>
export default {
  name: "ExpansionPanelGista",
  props: {
    // set to true to open when mounted
    expand: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      expanded: false,
    };
  },
  mounted() {
    if (this.expand) this.expanded = this.expand;
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: margin-top 0.3s;
}
.expand-enter,
.expand-leave-to {
  margin-top: -200%;
}
</style>
