<template>
  <div class="flex flex-col" v-click-outside="closeMonthPicker">
    <label
      class="flex items-center text-sm text-gray truncate font-medium"
      :class="{ 'text-error': error, 'mb-1': label }"
    >
      {{ label }} <span v-if="mandatory" class="text-error">*</span>
    </label>
    <div class="relative text-sm w-full">
      <button
        class="flex items-center justify-between px-3 py-2 w-full text-lg rounded-md border"
        :class="[
          {
            'bg-gray-lightest text-gray-light cursor-not-allowed': disabled,
            'text-gray-lightest': disabled,
            'bg-white border-primary focus:shadow-secondary-round focus:border-secondary':
              !disabled && !noBorder,
            'border-error': error,
            'hover:shadow-primary-sm': !noBorder && !disabled,
            'text-small h-11': size == 'small',
            'text-base': size == 'medium',
            'text-base h-12': size == 'large',
          },
          customClass,
        ]"
        @click="openMonthPicker"
      >
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          v-if="iconLeft"
        >
          <span :class="iconLeft"></span>
        </div>
        <span
          class="text-base"
          :class="{
            'pl-6': iconLeft,
            'cursor-not-allowed text-gray-light': disabled,
            'text-gray-light': value === '' || value === undefined,
            'text-gray': value !== '' && !disabled,
          }"
        >
          <slot name="selected" :slot-props="selected">{{
            selected || placeholder
          }}</slot>
        </span>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
          :class="!readOnly && toggleMonthPicker ? 'rotate-180' : 'rotate-0'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <transition
        enter-active-class="transform transition duration-500 ease-custom"
        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-300 ease-custom"
        leave-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <picker
          v-show="!readOnly && toggleMonthPicker"
          @change="changeDate"
          @reset="reset"
          :month="defaultMonth"
          :year="defaultYear"
          :previous-year="previousYear"
          :next-year="nextYear"
        >
        </picker>
      </transition>
    </div>
  </div>
</template>

<script>
import Picker from "./components/Picker.vue";
export default {
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  components: {
    Picker,
  },
  name: "MonthPickerGista",
  props: {
    size: {
      type: String,
      default: "large",
    },
    disabled: Boolean,
    noBorder: {
      type: Boolean,
      default: false,
    },
    error: {
      // error flag
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    nextYear: Number,
    previousYear: Number,
    placeholder: {
      type: String,
      default: "MM/YYYY",
    },
    value: {
      // for v-model
      type: [Object, String],
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      isInit: true,
      toggleMonthPicker: false,
      selected: this.value,
      internalValue: this.value,
      defaultMonth: null,
      defaultYear: null,
    };
  },
  methods: {
    changeDate(date) {
      if (!this.isInit) {
        const month = date.month.toString().padStart(2, "0");
        const formatted = `${month}/${date.year}`;
        this.internalValue = formatted;
        this.selected = formatted;
        this.$emit("input", this.internalValue);
        this.$emit("change", date);
      }
      this.isInit = false;
    },
    openMonthPicker() {
      if (this.disabled) {
        this.toggleMonthPicker = false;
      } else {
        this.toggleMonthPicker = !this.toggleMonthPicker;
      }
    },
    closeMonthPicker() {
      this.toggleMonthPicker = false;
      this.selected = null;
    },
    reset() {
      this.selected = null;
    },
    setDataSelected() {
      this.selected = this.value;
      this.internalValue = this.value;
      const [month, year] = this.internalValue.split("/");
      this.defaultMonth = parseInt(month);
      this.defaultYear = parseInt(year);
    },
  },
  mounted() {
    setTimeout(() => this.setDataSelected());
  },
};
</script>
