var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block font-medium text-sm text-gray mb-1",class:{ 'text-error': _vm.error }},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.mandatory)?_c('span',{staticClass:"text-error"},[_vm._v("*")]):_vm._e()])]),_c('textarea',_vm._g(_vm._b({staticClass:"border border-primary rounded-md p-3 w-full focus:ring-0 placeholder-gray-light",class:[
      {
        'border-primary': !_vm.error,
        'border-error': _vm.error,
        'bg-gray-lightest border-none': _vm.disabled,
      },
      {
        'border-secondary shadow-secondary-round hover:shadow-secondary-round':
          _vm.isFocus,
      },
      { 'bg-gray-lightest': _vm.disabled },
      { 'border-primary hover:shadow-primary-sm ': !_vm.error && !_vm.disabled },
      { 'border-gray-lightest': _vm.disabled },
      { 'border-error hover:shadow-primary-sm': _vm.error } ],attrs:{"disabled":_vm.$attrs.disabled},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false}}},'textarea',_vm.$attrs,false),_vm.listeners)),(this.$slots['message'] != undefined)?_c('div',{staticClass:"text-xs",class:[{ 'text-error': _vm.error }]},[_vm._t("message")],2):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.countLabel && _vm.$attrs.maxlength && !this.$slots['message']),expression:"countLabel && $attrs.maxlength && !this.$slots['message']"}],staticClass:"input-char-count text-secondary flex justify-between text-xs font-normal"},[_c('p',[_vm._v("Harap isi maksimal "+_vm._s(_vm.$attrs.maxlength)+" Karakter")]),_c('p',[_vm._v(" "+_vm._s(_vm.value ? _vm.value.length : 0)+" / "+_vm._s(_vm.$attrs.maxlength ? _vm.$attrs.maxlength : 0)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }