var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center cursor-pointer relative"},[_c('div',{staticClass:"flex items-center rounded-full p-1 duration-300 cursor-pointer",class:{
      'hover:shadow-gray-sm cursor-not-allowed':
        _vm.readOnly && !_vm.disabled && !_vm.value,
      'hover:shadow-primary-sm cursor-not-allowed':
        _vm.readOnly && !_vm.disabled && _vm.value,
      'bg-gray-light': !_vm.value,
      'bg-primary': _vm.value,
      'bg-gray-lightest cursor-not-allowed': _vm.disabled && !_vm.value,
      'bg-primary-lightest cursor-not-allowed': _vm.disabled && _vm.value,
      'w-12 h-7': _vm.size == 'md',
      'w-16 h-9': _vm.size == 'lg',
      'w-20 h-11': _vm.size == 'xl',
    },attrs:{"id":_vm.idName,"aria-checked":_vm.value.toString()},on:{"click":function($event){!_vm.disabled && !_vm.readOnly && _vm.toggle()}}},[_c('div',{staticClass:"bg-white rounded-full shadow-md transform duration-300",class:{
        'w-5 h-5': _vm.size == 'md',
        'w-7 h-7': _vm.size == 'lg',
        'w-9 h-9': _vm.size == 'xl',
        'translate-x-5': _vm.value && _vm.size == 'md',
        'translate-x-7': _vm.value && _vm.size == 'lg',
        'translate-x-9': _vm.value && _vm.size == 'xl',
      }})]),_c('span',{staticClass:"ml-2 text-gray-900 text-sm font-medium"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }